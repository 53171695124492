import MessageCard from '../MessageCard.vue';
import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import language from './messageLanguage'
export default defineComponent({
    name: "MessageList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const store = proxy.$store;
        proxy.addLangProps(language);
        let dataObj=reactive({
            pageListRef:null,
            canViewReceiveInfos:false,
            receiveStatusVisible:false,//签收情况弹出框影藏显示控制字段
            receiveStatusData:[],//签收情况
            pageList: {
                queryParam: {
                    modelFrom:'messagePublish'//表示发布通知公告列表，查看通知公告详情的时候要用到，以便判断哪些项不能显示
                },
                modelComp: MessageCard,
                modelMethod: utils.Api.buildUrl("/message/pageData")
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed------------
        const comboSelect=computed(()=>{
            return (params:any) => {
                let content=[] as any;
                switch (params) {
                    case 'flag':content=[{value:0,label:proxy.$t('message.msg_announcement')},{value:1,label:proxy.$t('message.msg_online')}]; break;
                    case 'type':content=[{value:1,label:proxy.$t('message.msg_system')},{value:2,label:proxy.$t('message.msg_corp')},
                        {value:3,label:proxy.$t('message.msg_role')},{value:4,label:proxy.$t('message.msg_person')}]; break;
                }
                return content;
            }
        })
        //请求某条通知公告的签收详情
        const receiveRequest=async(row:any)=>{
            let res = await utils.Api.messageReceiveDetail({id: row["F_ID"]});
            dataObj.receiveStatusData=res.receiveDetail;
        }
        //点击签收详情按钮事件
        const receiveDetailHandle=async(row:any)=>{
            await receiveRequest(row);
            dataObj.receiveStatusVisible=true;
        }
        //表格加载完毕事件
        const gridLoaded=(res:any)=>{
            dataObj.canViewReceiveInfos=res.canViewReceiveInfos;
        }
        return{
            ...toRefs(dataObj),comboSelect,receiveRequest,receiveDetailHandle,gridLoaded
        }
    },
    components: {}
});